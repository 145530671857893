.center-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
    text-align: center;
  }

  .center-box-text {
    padding-top: 4em;
  }

  .center-box-directions {
    padding-top: 5em;

  }